import { useEffect, useContext } from 'react';
import { Entry } from 'contentful';
import { NextRouter } from 'next/router';

import { ContentfulUserLandingPage } from 'src/types/common';
import { CONTENT_TYPES } from 'src/constants/contentful';
import { contentfulRevampClient } from 'src/services/contentful';
import logger from 'src/services/logger';
import { AppContext } from 'src/context/AppContext';

function useContentfulLandingPage(router: NextRouter) {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    const fetchData = async (locale: string) => {
      try {
        const userLandingPageData = await contentfulRevampClient.getEntries({
          content_type: CONTENT_TYPES.USER_LANDING_PAGE,
          include: 10,
          locale,
        });
        dispatch({
          type: 'SET_USER_LANDING_PAGE',
          payload:
            userLandingPageData.items as Entry<ContentfulUserLandingPage>[],
        });
      } catch (err) {
        logger.error(
          err as Error,
          'error fetching user landing page data from contentful',
        );
      }
    };

    void fetchData(router.locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale]);
}

export default useContentfulLandingPage;
