import styles from './Shimmer.module.css';

type ShimmerProps = {
  isLoading: boolean;
  children: React.ReactNode;
  className?: string;
};

const Shimmer = ({ isLoading, children, className }: ShimmerProps) => {
  if (isLoading) {
    return (
      <div className={`${styles.shimmer} ${className}`} aria-busy={true}>
        {children}
      </div>
    );
  }

  return <div className={`${className}`}>{children}</div>;
};

export default Shimmer;
