import { Tab, Image } from '@sumup/circuit-ui';
import React, { useContext } from 'react';

import styles from './headerNav.module.css';

import { ContentfulHeader } from 'src/types/common';
import { AppContext } from 'src/context/AppContext';

type HeaderNavProps = {
  categories: ContentfulHeader['userCategories'];
};

const HeaderNav: React.FC<HeaderNavProps> = ({ categories }) => {
  const { state, dispatch } = useContext(AppContext);

  const changeUserCategory = (category: string) => {
    dispatch({ type: 'SET_USER_CATEGORY', payload: category });
    localStorage.setItem('currentUserCategory', category);
  };

  return (
    <div className={styles.tabs}>
      {categories.map((category, index) => (
        <Tab
          key={`${category.sys.id}${index}`}
          selected={state.currentUserCategories === category.fields.title}
          onClick={() => changeUserCategory(category.fields.title)}
          className={styles.tab}
        >
          <Image
            src={`https:${category.fields.icon.fields.file.url}`}
            alt={category.fields.icon.fields.title}
            className={styles.icon}
          />
          <span>{category.fields.title}</span>
        </Tab>
      ))}
    </div>
  );
};

export default HeaderNav;
