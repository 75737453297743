import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Entry } from 'contentful';
import { Headline, Button } from '@sumup/circuit-ui';
import { Plus, Minus } from '@sumup/icons';

import useScreenWidth from '../hooks/useScreenWidth';

import Product from './Product';
import OtherGuide from './OtherGuide';
import styles from './products.module.css';
import SectionHeader from './SectionHeader';
import useContentfulLandingPage from './hooks/useContentfulLandingPage';
import PopularTopics from './PopularTopics';

import { AppContext } from 'src/context/AppContext';
import { ContentfulTopicSelector, ContentfulCarousel } from 'src/types/common';

const Products: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const router = useRouter();
  useContentfulLandingPage(router);

  const screenWidth = useScreenWidth();
  const tabletView = screenWidth >= 768 && screenWidth <= 1280;

  const [showMoreTopics, setShowMoreTopics] = useState(6);
  const [showMoreGuides, setShowMoreGuides] = useState(tabletView ? 3 : 4);

  const [currentProducts, setCurrentProducts] =
    useState<Entry<ContentfulCarousel>>(null);
  const [currentPopularTopics, setCurrentPopularTopics] =
    useState<Entry<ContentfulTopicSelector>>(null);
  const [currentOtherGuides, setCurrentOtherGuides] =
    useState<Entry<ContentfulCarousel>>(null);

  const topicSections = currentPopularTopics?.fields.topicSections;
  const otherGuides = currentOtherGuides?.fields.products;
  const products = currentProducts?.fields.products;

  useEffect(() => {
    const storedCategory = localStorage.getItem('currentUserCategory');
    if (storedCategory) {
      dispatch({ type: 'SET_USER_CATEGORY', payload: storedCategory });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProducts]);

  const categoryIndex =
    state.currentUserCategories === state.userLandingPage[0]?.fields.title
      ? 0
      : 1;

  useEffect(() => {
    if (tabletView) {
      setShowMoreGuides(3);
    } else {
      setShowMoreGuides(4);
    }
  }, [tabletView]);

  useEffect(() => {
    if (state.userLandingPage[categoryIndex] !== undefined) {
      dispatch({
        type: 'SET_USER_CATEGORY',
        payload: state.userLandingPage[categoryIndex].fields.title,
      });

      setCurrentProducts(
        state.userLandingPage[categoryIndex].fields.primaryCarousel,
      );
      setCurrentPopularTopics(
        state.userLandingPage[categoryIndex].fields.popularTopics,
      );
      setCurrentOtherGuides(
        state.userLandingPage[categoryIndex].fields.secondaryCarousel,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userLandingPage, categoryIndex]);

  const showMore = () => {
    if (showMoreTopics === 6 || showMoreTopics === 4) {
      setShowMoreTopics(topicSections.length);
    } else {
      setShowMoreTopics(screenWidth < 768 ? 4 : 6);
    }
  };

  return (
    <>
      <SectionHeader />
      {products && (
        <div className={styles.productsContainer}>
          {products.map((product, index) => (
            <Product key={`${product.sys.id}${index}`} product={product} />
          ))}
        </div>
      )}
      <div className={styles.popularTopicsContainer}>
        <Headline as="h3" className={styles.popularTopicsHeadline}>
          {currentPopularTopics?.fields.label}
        </Headline>
        <div className={styles.topicSections}>
          {topicSections
            ?.slice(0, showMoreTopics)
            .map((topicSection, index) => (
              <PopularTopics
                key={`${topicSection.sys.id}${index}`}
                topics={topicSection}
              />
            ))}
        </div>
        {showMoreTopics <= topicSections?.length ? (
          <Button
            variant="tertiary"
            onClick={showMore}
            className={styles.showMoreButton}
            icon={() =>
              showMoreTopics < topicSections?.length ? (
                <Plus size="16" />
              ) : (
                <Minus size="16" />
              )
            }
          >
            {showMoreTopics < topicSections?.length
              ? currentPopularTopics?.fields.expandButton
              : currentPopularTopics?.fields.closeButton}
          </Button>
        ) : null}
      </div>
      <div className={styles.otherGuidesWrapper}>
        <Headline as="h3" size="two" className={styles.otherGuidesHeadline}>
          {currentOtherGuides?.fields.label}
        </Headline>
        <div className={styles.otherGuidesContainer}>
          {otherGuides?.slice(0, showMoreGuides).map((otherGuide, index) => (
            <OtherGuide
              key={`${otherGuide.sys.id}${index}`}
              product={otherGuide}
            />
          ))}
        </div>
        {showMoreGuides <= otherGuides?.length ? (
          <Button
            className={styles.showMoreButton}
            variant="tertiary"
            icon={() =>
              (!tabletView && showMoreGuides < otherGuides?.length) ||
              (!tabletView && otherGuides?.length === 4) ||
              (tabletView && showMoreGuides < otherGuides?.length) ? (
                <Plus size="16" />
              ) : (
                <Minus size="16" />
              )
            }
            onClick={() => {
              if (
                (!tabletView && showMoreGuides < otherGuides?.length) ||
                (!tabletView && otherGuides?.length === 4) ||
                (tabletView && showMoreGuides < otherGuides?.length)
              ) {
                setShowMoreGuides(otherGuides.length);
              } else {
                setShowMoreGuides(tabletView ? 3 : 4);
              }
            }}
          >
            {(!tabletView && showMoreGuides < otherGuides?.length) ||
            (!tabletView && otherGuides?.length === 4) ||
            (tabletView && showMoreGuides < otherGuides?.length)
              ? currentOtherGuides?.fields.expandButton
              : currentOtherGuides?.fields.closeButton}
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default Products;
