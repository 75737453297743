import React, { useContext } from 'react';
import { BodyLarge, Headline, SearchInput, Title } from '@sumup/circuit-ui';

import useScreenWidth from '../hooks/useScreenWidth';

import styles from './header.module.css';

import { AppContext } from 'src/context/AppContext';

const Header: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const splitText = state.header.fields.title.split(' ');
  const boldIndex = splitText.findIndex(
    (word) => word.toLowerCase().replace('?', '') === 'help',
  );
  const screenWidth = useScreenWidth();

  return (
    <div className={styles.header}>
      {screenWidth <= 768 ? (
        <Headline as="h1" size="one" className={styles.title}>
          {splitText.map((word, index) => (
            <span
              key={index}
              className={
                index === boldIndex
                  ? styles['bold-text']
                  : styles['normal-text']
              }
            >
              {word} {index < splitText.length - 1 && ' '}
            </span>
          ))}
        </Headline>
      ) : (
        <Title as="h1" size="three" className={styles.title}>
          {splitText.map((word, index) => (
            <span
              key={index}
              className={
                index === boldIndex
                  ? styles['bold-text']
                  : styles['normal-text']
              }
            >
              {word} {index < splitText.length - 1 && ' '}
            </span>
          ))}
        </Title>
      )}
      <BodyLarge className={styles.description}>
        {state.header.fields.subTitle}
      </BodyLarge>
      <div className={styles.searchContainer}>
        <SearchInput
          inputClassName={styles.searchInput}
          hideLabel
          clearLabel="Clear"
          label="Search"
          onClick={() => {
            dispatch({ type: 'SET_SEARCH_OVERLAY', payload: true });
          }}
          onClear={() => {}}
          placeholder="Search articles"
        />
      </div>
    </div>
  );
};

export default Header;
