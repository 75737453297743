import React from 'react';

import Landing from 'components/revamp/Landing';

const NewScPage = () => (
  <>
    <Landing />
  </>
);

export default NewScPage;
