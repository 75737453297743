import { Card, Headline, Body, Image } from '@sumup/circuit-ui';
import { Entry } from 'contentful';
import React, { useState } from 'react';
import { useRouter } from 'next/router';

import Shimmer from '../../Shimmer';

import styles from './otherGuide.module.css';

import { ContentfulProduct } from 'src/types/common';

interface OtherGuideProps {
  product: Entry<ContentfulProduct>;
}

const OtherGuide: React.FC<OtherGuideProps> = ({ product }) => {
  const router = useRouter();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Shimmer className={styles.container} isLoading={!isImageLoaded}>
      <Card
        className={styles.card}
        onClick={() => router.push(`/products/${product.fields.slug}`)}
      >
        <Image
          src={`https:${product?.fields?.softwareImage?.fields?.file?.url}`}
          alt={product?.fields?.softwareImage?.fields?.description}
          className={styles.image}
          onLoad={() => setIsImageLoaded(true)}
          onError={() => setIsImageLoaded(true)}
        />
        <div className={styles.descriptionWrapper}>
          <Headline className={styles.headline} as="h2" size="three">
            {product?.fields?.introCard.fields.title}
          </Headline>
          <Body size="two" className={styles.subHeadline}>
            {product?.fields?.introCard.fields.description}
          </Body>
        </div>
      </Card>
    </Shimmer>
  );
};

export default OtherGuide;
