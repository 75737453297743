import { Body, Card, Headline, Image } from '@sumup/circuit-ui';
import { Entry } from 'contentful';
import React, { useState } from 'react';
import { NextRouter, useRouter } from 'next/router';

import Shimmer from '../../Shimmer';

import styles from './populartopics.module.css';

import { ContenfulTopicSection } from 'src/types/common';

interface PopularTopicsProps {
  topics: Entry<ContenfulTopicSection>;
}

const PopularTopics: React.FC<PopularTopicsProps> = ({ topics }) => {
  const router: NextRouter = useRouter();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Shimmer isLoading={!isImageLoaded} className={styles.popularTopic}>
      <Card
        className={styles.card}
        onClick={() => router.push(`/topics/${topics.fields.slug}`)}
      >
        <div className={styles.cardHeader}>
          <Image
            className={styles.cardImage}
            src={`https:${topics.fields.icon.fields?.file.url}`}
            alt={topics.fields.icon.fields?.title}
            onLoad={() => setIsImageLoaded(true)}
            onError={() => setIsImageLoaded(true)}
          />
        </div>
        <Headline as="h3" size="four" className={styles.cardTitle}>
          {topics.fields.introCard.fields.title}
        </Headline>
        <Body as="h3" className={styles.cardDescription}>
          {topics.fields.introCard.fields.description}
        </Body>
      </Card>
    </Shimmer>
  );
};

export default PopularTopics;
