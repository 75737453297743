import React, { useContext } from 'react';
import { Headline } from '@sumup/circuit-ui';

import HeaderNav from '../HeaderNav';

import styles from './header.module.css';

import { AppContext } from 'src/context/AppContext';

const SectionHeader: React.FC = () => {
  const { state } = useContext(AppContext);

  const categoryIndex =
    state.currentUserCategories === state.userLandingPage[0]?.fields.title
      ? 0
      : 1;

  const userCategory = state.userLandingPage[categoryIndex];

  return state.userLandingPage && userCategory ? (
    <div className={styles.container}>
      <div className={styles.headline}>
        <Headline as="h3" size="two">
          {userCategory.fields.primaryCarousel.fields.label}
        </Headline>
      </div>
      <HeaderNav categories={state.header.fields.userCategories} />
    </div>
  ) : (
    <></>
  );
};

export default SectionHeader;
