/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

import TopicName from 'components/TopicName';
import { Col, Row } from 'components/TypedCircuit';
import TypedSections from 'components/TypedSections';
import { TypeContainer } from 'components/styledComponents';
const OldScPage = ({ sectionTree, articles, sectionTypeTree }) => (
  <Col span="12" skip="0">
    {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(sectionTypeTree).map((topic) => (
        <Row key={topic}>
          {TopicName(topic)}
          <TypeContainer>
            <TypedSections
              sectionTree={sectionTree}
              articles={articles}
              topic={topic}
              sectionTypeTree={sectionTypeTree}
            />
          </TypeContainer>
        </Row>
      ))
    }
  </Col>
);

export default OldScPage;
