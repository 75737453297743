/* eslint-disable */
import React, { type FC, useMemo } from "react";
import Link from "next/link";
import { Headline, Body } from "@sumup/circuit-ui";
import { ChevronRight } from "@sumup/icons";
import { useTranslation } from "next-i18next";

import { SUBPATH_TYPES } from "constants/common";
import type {
	SectionTree,
	SectionTreeItem,
	SectionTypeTree,
} from "types/common";
import type { LandingPageArticles } from "types/index";
import { addSlugToUrl } from "services/common";
import {
	Hyperlink,
	SectionCard,
	SectionContainer,
	StyledIcon,
	ViewArticle,
	SectionCardContent,
	SectionCardNavigation,
} from "components/styledComponents";
import PosProTypedSection from "./PosProTypedSection";
import { useRouter } from "next/router";
import { getCountryFromLocale } from "src/services/localization";

const SECTION_ICON_SIZE = "112";

const DEFAULT_SECTION_TYPE = "Product";

interface SectionsProps {
	articles: LandingPageArticles;
	isPos?: boolean;
	sectionTree: SectionTree;
	sectionTypeTree: SectionTypeTree;
	topic?: string;
}

const TypedSections: FC<SectionsProps> = ({
	articles,
	isPos,
	sectionTree,
	sectionTypeTree,
	topic = DEFAULT_SECTION_TYPE,
}) => {
	const { t } = useTranslation();

	const sortedSectionsData: Array<
		SectionTreeItem & {
			id: string;
		}
	> = useMemo(
		() =>
			sectionTypeTree[topic]
				.map((sectionId) => ({ ...sectionTree[sectionId], id: sectionId }))
				.sort((nextSection, prevSection) =>
					nextSection.sectionTitle > prevSection.sectionTitle ? 1 : -1,
				),
		[sectionTree, sectionTypeTree, topic],
	);

	const router = useRouter();
	const country = getCountryFromLocale(router.locale);


	return (
		<>
			{sortedSectionsData.map((section) =>
				section.articles.length ? (
					<SectionContainer
						key={section.id}
						// Not using span due to type error
						// REMINDER:
						// Speak with Connor about finding a fix for this
						// span={{ default: 12, kilo: 12, mega: 6, giga: 6, tera: 4 }}
					>
						<SectionCard spacing={"giga"}>
							<SectionCardContent>
								{section.icon ? (
									<StyledIcon
										src={`${section.icon.url}?w=${SECTION_ICON_SIZE}&h=${SECTION_ICON_SIZE}`}
										alt={section.icon.name}
										width={SECTION_ICON_SIZE}
										height={SECTION_ICON_SIZE}
									/>
								) : null}
								<Headline size="four" as="h3">
									{section.sectionTitle}
								</Headline>
								{section.articles
									.filter((articleId) => articles?.[articleId]?.title)
									.slice(0, 3)
									.map((articleId) => (
										<Body key={articleId}>
											<Link
												passHref
												href={addSlugToUrl({
													subPath: SUBPATH_TYPES.articles,
													contentId: articleId,
													contentSlug: articles[articleId].articleSlug,
													isPos,
												})}
											>
												<Hyperlink size="one">
													{articles[articleId].title}
												</Hyperlink>
											</Link>
										</Body>
									))}
							</SectionCardContent>
							<SectionCardNavigation>
								<Body>
									<Link
										passHref
										href={addSlugToUrl({
											subPath: SUBPATH_TYPES.sections,
											contentId: section.id,
											contentSlug: section.slug,
											isPos,
										})}
									>
										<ViewArticle variant="highlight">
											{t("view_all_articles")}
											<ChevronRight aria-hidden="true" />
										</ViewArticle>
									</Link>
								</Body>
							</SectionCardNavigation>
						</SectionCard>
					</SectionContainer>
				) : null,
			)}
			{topic === "Product" && country.toLowerCase() === "gb" && (
				<PosProTypedSection />
			)}
		</>
	);
};

export default TypedSections;
