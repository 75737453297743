import React, { useState, useEffect, useCallback } from 'react';
import { NextPage, GetStaticProps } from 'next';
import { useRouter } from 'next/router';

import { NewScPage, OldScPage } from '../components/indexPages';

import { Props } from 'types/index';
import { getIndexStaticProps } from 'services/staticGeneration/index';
import {
  getOptimizelyAnonymousUser,
  optimizelyClient,
} from 'src/services/optimizely/client';

const Page: NextPage<Props> = ({ sectionTree, articles, sectionTypeTree }) => {
  const router = useRouter();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [isEnabled, setIsEnabled] = useState(null);
  const [isNewMSC, setIsNewMSC] = useState(false);

  const newMSCCheck = useCallback(async () => {
    const country = router.locale.split('-')[1];
    const experimentKey = '_support_centre__multi-product_sc';
    const user = getOptimizelyAnonymousUser(country);
    await optimizelyClient.onReady();
    const variationKey = optimizelyClient.decide(
      experimentKey,
      null,
      user.id,
      user.attributes,
    );
    return variationKey.enabled;
  }, []);

  useEffect(() => {
    setIsEnabled(null);
    newMSCCheck()
      .then((val) => {
        setIsNewMSC(val);
        setIsEnabled(true);
      })
      .catch(() => {
        setIsNewMSC(false);
      });
  }, []);

  if (isEnabled === null) {
    return <></>;
  }
  return (
    <>
      {isNewMSC && <NewScPage />}
      {!isNewMSC && (
        <OldScPage
          articles={articles}
          sectionTree={sectionTree}
          sectionTypeTree={sectionTypeTree}
        />
      )}
    </>
  );
};

// To bring back once we enable en-GB
export const getStaticProps: GetStaticProps = getIndexStaticProps;

export default Page;
