import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { TopicTitle } from '../styledComponents';

const TopicName: FC<string> = (topic) => {
  const { t } = useTranslation();
  const topicKey = topic.toLowerCase();
  return (
    <TopicTitle as="h2" size="two">
      {t(`common.browse_by.${topicKey}`)}
    </TopicTitle>
  );
};

export default TopicName;
