import { Card, Headline, Image } from '@sumup/circuit-ui';
import type { Entry } from 'contentful';
import type React from 'react';
import { useState } from 'react';
import { useRouter } from 'next/router';

import Shimmer from '../../Shimmer';
import useScreenWidth from '../../hooks/useScreenWidth';

import styles from './product.module.css';

import type { ContentfulProduct } from 'src/types/common';

interface ProductProps {
  product: Entry<ContentfulProduct>;
}

const Product: React.FC<ProductProps> = ({ product }) => {
  const router = useRouter();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const screenWidth = useScreenWidth();

  return (
    <div className={styles.product}>
      <Card
        className={styles.card}
        onClick={() => router.push(`/products/${product.fields.slug}`)}
      >
        <Shimmer isLoading={!isImageLoaded}>
          <Image
            className={styles.image}
            src={`https:${product?.fields?.hardwareImage?.fields?.file?.url}`}
            alt={product?.fields?.introCard.fields.title}
            onLoad={() => setIsImageLoaded(true)}
            onError={() => setIsImageLoaded(true)}
          />
        </Shimmer>
      </Card>
      <Shimmer isLoading={!isImageLoaded}>
        <Headline
          className={styles.headline}
          as="h1"
          size={screenWidth <= 780 ? 'two' : 'one'}
        >
          {product?.fields?.introCard.fields.title}
        </Headline>
      </Shimmer>
    </div>
  );
};

export default Product;
