import React, { useContext } from 'react';

import styles from './landing.module.css';
import useContentfulHeader from './hooks/useContentfulHeader';

import Header from 'components/revamp/Header';
import Products from 'components/revamp/Products';
import { AppContext } from 'src/context/AppContext';

const Landing: React.FC = () => {
  const isLoaded = useContentfulHeader();
  const { state } = useContext(AppContext);

  return isLoaded && state.header ? (
    <div className={styles.container}>
      <header className={styles.header}>
        <Header />
      </header>
      <main className={styles.main}>
        <Products />
      </main>
    </div>
  ) : (
    <></>
  );
};

export default Landing;
